import React from "react";
import { Container, Jumbotron } from 'react-bootstrap';


export default function ThankYou() {


    return (
        <div style={{ marginTop: '150px' }}>
            <Container>
                <Jumbotron>
                    <h2 className='container'>Oh no! Page not found.</h2>
                </Jumbotron>
            </Container>
        </div>
    );
};
