import React, { useState, useEffect, useContext } from 'react';
import { Container, Jumbotron } from 'react-bootstrap';
import TableContainer from './TableContainer';
import { DialerInitContext } from './DialerInitContext';
import useDebounce from './useDebounce';
import config from '../config';

const socket = new WebSocket( config.ws_apiUrl ); 

export default function Dialer(props) {
	const { recipients, setRecipients, requestParamsString } = useContext(DialerInitContext);
    const [ callSequence, setCallSequence ] = useState({ i: 0, next: true });
    const [ showStart, setShowStart ] = useState(true);
	const telephonySessionConditionsArray = ['Disconnected', 'FaxReceive', 'VoiceMailScreening'];
	const disconnectedReasonArray = ['Pickup', 'Supervising', 'TakeOver', 'Timeout', 'BlindTransfer', 'RccTransfer', 'AttendedTransfer', 'CallerInputRedirect', 'CallFlip', 'ParkLocation', 'DtmfTransfer', 'AgentAnswered', 'AgentDropped', 'Rejected', 'Cancelled', 'InternalError', 'NoAnswer', 'TargetBusy', 'InvalidNumber', 'InternationalDisabled', 'DestinationBlocked', 'NotEnoughFunds', 'NoSuchUser', 'CallRedirected', 'CallReplied', 'CallFinished', 'CallDropped']

	const updateRecipientsAndSessionStorage = ( newRecipientsObj ) => {
		setRecipients(newRecipientsObj);
		sessionStorage.setItem("recipients", JSON.stringify(newRecipientsObj));
	};

	// websocket effect
	useEffect(() => {
		// console.log('in')
		socket.onopen = (e) => {
			console.log('WebSocket Client Connected to', e.target.url);
			socket.send(JSON.stringify({ id: '01', welcomeMessage: 'Hello Server!' }));
		};
		socket.onmessage = (message) => {
			let wsRes = JSON.parse(message.data);

			try{
				if ( wsRes && wsRes.rcRes.parties){
					console.log('got reply! => id', wsRes.id, 'telephony session', wsRes.rcRes.parties);
					let newRecipients = {...recipients};
					newRecipients[wsRes.id].ringOutCallData = wsRes.rcRes;
				
					updateRecipientsAndSessionStorage(newRecipients);
		
					const { parties }  = newRecipients[wsRes.id].ringOutCallData;
					const [ oStatus, iStatus ] = parties;
		
					// if ( intervalConditionsArray.indexOf(calleeStatus) !== -1 ||  intervalConditionsArray.indexOf(callStatus) !== -1 ){
					disconnectedReasonArray.indexOf(iStatus.status.reason) !== -1 && deleteCall();
	
					if ( telephonySessionConditionsArray.indexOf(iStatus.status.code) !== -1 || telephonySessionConditionsArray.indexOf(oStatus.status.code) !== -1 ){
						if ( callSequence.next === true ){
							if ( callSequence.i < Object.keys(recipients).length -1  ){
								console.log('WS: after conditions array')
								let newCallSequence = callSequence.i + 1 ;
								setCallSequence({...callSequence, i: newCallSequence });
							} else {
								setCallSequence({...callSequence, i: 0, next: true });
								setTimeout(() => {setShowStart(true);}, 1000) 
							};
						} else {
							setCallSequence({...callSequence, next: true });
						};
					};
				};
				if ( wsRes && wsRes.rcRes.callData){
					console.log('got reply! => id', wsRes.id, 'call log', wsRes.rcRes.callData);
					let newRecipients = {...recipients};
					newRecipients[wsRes.id].ringOutCallData.callLogData = wsRes.rcRes.callData;
				
					updateRecipientsAndSessionStorage(newRecipients);
				}
			} catch(err) { console.log(err) }
		};
	}, [socket.onmessage]);


	//callSequence effect
	useEffect(() => {
		if (callSequence.next !== false && callSequence.i > 0 && callSequence.i < Object.keys(recipients).length){
			deleteCall();
			dial(callSequence.i);
		};
	},[callSequence.i]);

	const removeRecipient = (contactId) => {
		const key = contactId;
		const { [key]: _, ...newRecipients } = recipients;
		updateRecipientsAndSessionStorage(newRecipients);
	};


	const deleteCall =() => {
		// hangup current active call
		document.querySelector("#rc-widget-adapter-frame").contentWindow.postMessage({
			type: 'rc-adapter-control-call',
			callAction: 'hangup',
		}, '*');
	};

	const cancelAllCalls = () => {
		setCallSequence({...callSequence, next: false });
		setShowStart(true);
		deleteCall();
	};

	const dialToCall = ( phone, key ) => {
		let user = requestParamsString.user;
		document.querySelector("#rc-widget-adapter-frame").contentWindow.postMessage({
			type: 'rc-adapter-new-call',
			phoneNumber: `+1${phone}`,
			toCall: true,
		}, '*');

		const activeCallNotify = (e) => {
			const data = e.data;
			if (data && data.type === 'rc-active-call-notify') {
				if ( data.call.to.phoneNumber === `+1${phone}`){
					let callDataUri = data.call.uri;
					let partyId = data.call.partyId;
					let telephoneSessionUri = `/restapi/v1.0/account/~/telephony/sessions/${data.call.telephonySessionId}`
					socket.send(JSON.stringify({ id: key, sender: user, telephoneSessionUri, callDataUri, partyId }));
					// socket.send(JSON.stringify({ id: key, sender: user, partyId }));
					window.removeEventListener('message', activeCallNotify, true );	
				};
			};
		};
		window.addEventListener('message', activeCallNotify, true);
	};

	const dial =  () => {
		let sequence = callSequence.i;
		if (sequence >= 0  ){
			showStart && setShowStart(false);
			setCallSequence({ ...callSequence, next: true });
			let keys = Object.keys(recipients);
			if (keys.length > 0){
				// let user = requestParamsString.user;
				let key = keys[sequence];				
				if ( recipients[key].account.status !== 'Do Not Call') {
					// set recipient call status to 'setup' to prevent deleting row during call initialization
					let tempRecipientCallStatus = { ...recipients };
					tempRecipientCallStatus[key].ringOutCallData = {
						parties: [
							{ direction:"Outbound", status: { code: "setup" } },
							{ direction:"Inbound", status: { code: "setup" } }
						]
					};
					( async() =>  await setRecipients(tempRecipientCallStatus) )();
					let phone = recipients[key].defaultDialTo.replace(/\D+/g, '');
					dialToCall(phone, key);
				};
				if (recipients[key].account.status === 'Do Not Call'){
					if ( callSequence.i < keys.length -1 ){
						let newCallSequence = callSequence.i + 1 ;
						setCallSequence({...callSequence, i: newCallSequence });
					} else {
						setCallSequence({...callSequence, i: 0 });
					};
				};
			};
		};
	};
	// const handleClickEvent = useDebounce(() => console.log('click'), 1000 )

	const debouncedStart = useDebounce( () => dial(), 500);
	const debouncedStop = useDebounce(() => { cancelAllCalls(); }, 500);

	const controllerBtn = () => (
		showStart
			? 
			<button onClick={() => debouncedStart() } className ='btn btn-primary'><span className="d-flex align-items-center">Start Session&nbsp;&nbsp;<i className="fa fa-play"></i></span></button>
			:
			<button onClick={() => debouncedStop() } className='btn btn-danger mr-3'><span className="d-flex align-items-center">Pause All&nbsp;&nbsp;<i className="fa fa-pause"></i></span></button>
	);

    return (
		<Container className="p-3">
			<Jumbotron className='shadow rounded'>
				<div className='d-flex justify-content-between pr-2 pl-2'>
					<div><h2>Purple Dialer</h2></div>
					<div>
						{ controllerBtn() }
					</div>
				</div>
				<TableContainer data = {{ recipients, removeRecipient, deleteCall, showStart }} sequence = { callSequence.i }/>
			</Jumbotron>
		</Container>
    );
};
