//Environment Specific Config
let config = module.exports = {};
//
config.env  = 'staging';
config.port = '3213';
config.host = 'https://stagingsecure.uplyftcapital.com:' + config.port;
//
//API URL
config.apiUrl = 'https://stagingcrm.uplyftcapital.com';
config.ws_apiUrl = 'wss://stagingcrm.uplyftcapital.com/dialer';
