import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DialerInitContextProvider from './components/DialerInitContext';
import Dialer from './components/Dialer';
import Error from './components/Error';
import './index.css';



function App() {

  return (
    <Router basename={'/purpleDialerUi'}>
      <Switch>
        <DialerInitContextProvider>
            <Route exact path= '/powerDialer' render={props => <Dialer {...props} />} />
        </DialerInitContextProvider>
        <Route render={props => <Error {...props} />} />
      </Switch>
    </Router>
  );
}

export default App;