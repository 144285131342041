import React, { useState, useEffect, createContext } from 'react';
import { useLocation } from "react-router-dom";
import QueryString from 'query-string';
import axios from 'axios';
import config from '../config';

export const DialerInitContext = createContext();

export default function DialerInitContextProvider(props) {
	const [ requestParamsString, setRequestParamsString ] = useState({});
    const [ recipients, setRecipients ] = useState({});

    const location = useLocation();

    const paramsString = async ( params, cb) => {
		sessionStorage.clear();
        if (params) {
			if (params.collectionsParams ) {
				let collections = JSON.parse(params.collectionsParams);
				let paramsObj = {
					type: collections.identifier,
					recipients: collections.recipients,
					user: JSON.parse(params.user)
				};
				setRequestParamsString(paramsObj);
				sessionStorage.setItem("params", JSON.stringify(paramsObj));
				cb( paramsObj );
			};
		};
	};

	const fetchAccounts = (requestParams, type ) => {
		console.log('config.apiUrl', config.apiUrl)
		axios.get(`${config.apiUrl}/api/powerDialer/prepareList?user=${JSON.stringify(requestParams.user)}&collectionsParams=${JSON.stringify(requestParams)}&identifier=bulkCall`) 
			.then( res => { 
				setRecipients(res.data);
				sessionStorage.setItem("recipients", JSON.stringify(res.data));
			})
			.catch(err => { console.error('Error: ', err )})
	};

	const checkEqualArray = (arr1, arr2) => {
		return JSON.stringify(arr1.sort( (a, b) =>  a - b )) === JSON.stringify(arr2.sort( (a, b) =>  a - b ));
    };
   
	//location effect
	useEffect(() => {
		const params = QueryString.parse(location.search);
        let collections = JSON.parse(params.collectionsParams);

		if (sessionStorage['params']){
			let tempState = JSON.parse(sessionStorage.getItem("params"));
			let equalLength = collections.recipients.length === tempState.recipients.length;

			if ( !equalLength ){
				checkEqualArray( collections.recipients, tempState.recipients ) ? setRequestParamsString(tempState) : paramsString(location, fetchAccounts);
			} else {
				setRequestParamsString(tempState)
			}; 
		};

		if (sessionStorage['recipients'] ){
			let tempState = JSON.parse(sessionStorage.getItem("recipients"));
			setRecipients(tempState);
		} else {
			paramsString(params, fetchAccounts);
		};
		
	},[location]);

    return (
        <DialerInitContext.Provider value={{ recipients, setRecipients, requestParamsString }}>
            {props.children}
        </DialerInitContext.Provider>
    );
};
