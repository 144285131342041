import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { DialerInitContext } from './DialerInitContext';
// import QueueTable from './QueueTable';
import CallDataPopover from './CallDataPopover';
import config from '../config';

// import axios from 'axios';
import '../index.css';


export default function TableContainer({ data, sequence }) {

    const { recipients } = useContext(DialerInitContext);
    // const callStatusArr = [ 'setup', 'Proceeding', 'Answered',  ]
    
    return (
        <div className="modal-content">
            <div className="modal-body">
                <div className="row">
                    <div className="form-group col-12">
                        {/* <QueueTable sequence={sequence} contacts = {recipients} /> */}
                       
                        <Table id='dialerTable' className='table table-striped'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Recipient</th>
                                    <th>Type</th>
                                    <th>Account</th>
                                    <th>Phone</th>
                                    <th>Inbound Status/Reason</th>
                                    <th>Outbound Status/Reason</th>
                                    <th>Call Result/Duration</th>
                                    {/* <th>Action</th> */}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                { Object.keys(recipients).map( (id, index) => {
                                    const callStatusArr = [ 'VoiceMail', 'Proceeding', 'Answered' ];
                                    const { defaultDialTo,  name, type, account, ringOutCallData:cRc } = recipients[id];
                                    const { callLogData:callLog } = cRc;
                                    const [ oStatus, iStatus ] = cRc.parties || [];
                                    let styleCondition, closeBtnCondition;

                                    if (cRc.parties ){
                                        styleCondition = callStatusArr.indexOf( oStatus.status.code ) !== -1 ? true : false;
                                        closeBtnCondition = ( styleCondition || oStatus.status.code === 'setup' && !data.showStart )|| sequence > index  ? true : false;
                                    };

                                    return(
                                        <tr key={ id } className= { styleCondition ? 'inProgressCall' : '' }>
                                            {/* <td><img src='/svg/right_arrow.svg' style={{height:'20px'}} className= {sequence === index ? '' :' d-none'}/></td> */}
                                            <td><i style={{fontSize:'1.3rem', color:'#2ab382' }} className= {sequence === index ? "fa fa-arrow-circle-right" :' d-none'}></i></td>
                                            <td> <a href={`${config.apiUrl}/accounts/update/${account._id}#/#tab-contact-${id}`} target="_blank" rel="noopener noreferrer"><span>{ name }</span></a></td>
                                            <td> { type }</td>
                                            <td> <a href={`${config.apiUrl}/accounts/update/${account._id}`} target="_blank" rel="noopener noreferrer"><span className="d-inline-block text-truncate" style={{ maxWidth:' 150px' }} aria-label={ account.name } >{ account.name }</span></a></td>
                                            <td> <a href={`tel:${ defaultDialTo }`}>{ defaultDialTo }</a> </td>
                                            <td> 
                                                <span  className = { account.status === 'Do Not Call' ? 'btn-danger-light ul-pill' : '' }>{ account.status === 'Do Not Call' ? 'Do Not Call' :  iStatus && iStatus.status.code }</span>
                                                <span>{  iStatus && iStatus.status.reason && `/ ${iStatus.status.reason}` }</span>
                                            </td>
                                            <td> 
                                                <span  className = { account.status === 'Do Not Call' ? 'btn-danger-light ul-pill' : '' }>{ account.status === 'Do Not Call' ? 'Do Not Call' : oStatus && oStatus.status.code }</span>
                                                <span>{  oStatus && oStatus.status.reason && `/ ${oStatus.status.reason}` }</span>
                                            </td>
                                            <td> 
                                                { callLog && callLog.duration && `${callLog.result} / ${callLog.duration} sec` }  &nbsp;
                                                { callLog && callLog.duration && <CallDataPopover  data={recipients[id]} /> } 
                                            </td>
                                            {/* <td> { account.status === 'Do Not Call' ? '' : <span onClick = {() => data.deleteCall(id) } className='btn-sm btn-secondary-light'>skip &nbsp;<i className="fa fa-forward" aria-hidden="true"></i></span> }</td> */}
                                            <td className='d-flex justify-content-end'> <button onClick={() => data.removeRecipient(id, index)} className='btn btn-sm btn-danger'  disabled = { closeBtnCondition ? 'disabled':'' }><i className="fa fa-times"></i></button></td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};