import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';

export default function CallDataPopover({data}) {

    const popover = (
        <Popover id="callLog-Popover">
          <Popover.Title as="h3">Call Log</Popover.Title>
          <Popover.Content>
              <CallLog data = {data} />
          </Popover.Content>
        </Popover>
      );
      
      const CallDataLog = () => (
        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
          <i className= "fa fa-info-circle"></i>
        </OverlayTrigger>
      );
      
    return <CallDataLog />
};


const CallLog = ({data}) => {
  const {startTime, action, result, direction, reason, reasonDescription} = data.ringOutCallData.callLogData;
  return (
    <>
      <p><b>Start Time: </b>{dateFormat(startTime)}</p>
      <p><b>Action: </b>{action}</p>
      <p><b>Direction: </b>{direction}</p>
      <p><b>Result: </b>{result}</p>
      <p><b>Reason: </b>{reason}</p>
      <p><b>Description: </b>{reasonDescription}</p>
    </>
  );
};

const dateFormat = ( dateStr ) => {
  let date = new Date(dateStr);
  let options = {
    month: 'short', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: true,
  };
  let formatDate = new Intl.DateTimeFormat('en-US', options).format(date);
  return formatDate; //"Jul 29, 12:51:38 PM"
};
