//Environment Specific Config
let config = module.exports = {};

config.env = 'development';
config.port = '3000';
config.host = 'http://devfpp.uplyftcapital.com:' + config.port;

//API URL
config.apiUrl = 'http://devcrm.uplyftcapital.com:3210';
config.ws_apiUrl = 'ws://devcrm.uplyftcapital.com:3206';

// config.apiUrl = 'https://cab4b3a518aa.ngrok.io'; // ngrok testing
// config.ws_apiUrl = 'ws://localhost:3206/dialer';