import React, { useEffect, useRef } from 'react';

export default function useDebounce(cb, delay){
  const callback = useRef(); //The latest callback
  const timeOut = useRef(); // The latest timeOut

  useEffect(() => { 
      callback.current = cb;
  }, [cb]);

  return() => {
    if (timeOut.current) {
        clearTimeout(timeOut.current);
    }

    timeOut.current = setTimeout(() => { callback.current(); }, delay);
  };
};